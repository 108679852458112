import React from 'react';
import { Card, CardContent,FormControl,Modal,Box, Button, TextField, Divider, Typography, Grid, Checkbox, Tooltip, InputAdornment, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../styles/project.css'
import { useLocation } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import '../styles/common.css';
import '../styles/custom.css';
import {getEnviromentList,createEnviroment,deleteEnviroment,updateEnviroment} from "../redux/actions/enviroment"
import { getProjects } from "../redux/actions/auth";
import ErrorBox from '../components/ErrorBox';
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid";
import { modalPopUpStyleLarge } from '../styles/getLPTheme';
import Persona from './EnvironmentPersona'
import EnviromentService from '../services/enviroment.service';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import showConfirmationAlert from "../components/Confirm";
import { resetEnv_Persona } from "../redux/actions/environmentPersona";






const ProjectManagement = ({
    getProjects,
    listOfProject,
    getEnviromentList,
    createEnviroment,
    deleteEnviroment,
    updateEnviroment,
    listOfEnviroment,
    resetEnv_Persona
}) => {
    const [Id,setId] = useState(null);
    const [UserName, setUserName] = useState(null);
    const [EnviromentName, setEnviromentName] = useState(null);
    const [Password, setPassword] = useState(null);
    const [IsActive, setIsActive] = useState(true);
    const [Base_url, setBaseUrl] = useState(null);
    const [error, setError] = useState('');
    const [urlerror, setUrlError] = useState('');
    const [showEditButton, setShowEditButton] = useState(false);
    const [isDisbaled, setisDisbaled] = useState(false); 
    const [EnviromentList, setEnviromentList] = useState([]);
    const [EnviromentData, setEnviromentData] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedProject, setSelectedProject] = useState('select');
    const [globalError, setGlobalError] = useState('');
    const [isOpen, setIsOpen] = useState(false);




    const [paginationModals, setPaginationModals] = useState({
        page: 0,
        pageSize: 10,
    });
    const [sortModels, setSortModels] = useState([]);
    const [filterModels, setFilterModels] = useState({ items: [] });
    const columns = [
        { field: "proj_name", headerName: "Project" },
        { field: "name", headerName: "Name" },
        { field: "username", headerName: "UserName" },
        {
            field: "password", headerName: "Password", renderCell: (params) => (
                <span>{'•'.repeat(params.value.length)}</span> // Mask the token value
            ),
        },
        { field: "url", headerName: "URL" },
        { field: "is_active", headerName: "Is Active" },
        { field: "u_name", headerName: "Created By" },
        {
            field: "aciton",
            headerName: "Actions",
            renderCell: (params) => (
                <>
                    <Tooltip title="Add Persona"><PersonAddIcon className="action-icon" onClick={() => handleAddPersona(params.row)} titleAccess='Add Persona' /></Tooltip>
                    <Tooltip title="Verify Credentials"><PlayCircleOutlineIcon className="action-icon" onClick={() => checkConnection(params.row)} titleAccess='Verify Credentials' /></Tooltip>
                    <Tooltip title="Edit"><EditIcon className="action-icon" onClick={() => UpdateProjEnvironment(params.row)} titleAccess='Edit' /></Tooltip>
                    <Tooltip title="Delete"><DeleteIcon className="action-icon-delete" onClick={() => Delete(params.row)}/></Tooltip>

                </>
            ),
        },

    ];
    const [width, setWidth] = useState(100 / columns.length + '% !important');

    const handleSelectChange = (event) => {
        setSelectedProject(event.target.value);
    };
    const handleAddPersona = (row) => {
        setEnviromentData(row)
        setIsOpen(true);
    };

    const handleAddPersonaClose = () => {
        resetEnv_Persona();
        setEnviromentData(null);
        setIsOpen(false);
    };

    // useEffect(()=>{

    //     if(UserName && EnviromentName && Password && Base_url && selectedProject!== 'select' && selectedProject.length>0){
    //         setIsDisabled(false)
    //     }
    // },[isDisabled])
    

    const onChangeUserName = (event) => {
        setUserName(event.target.value);
    }
    const onChangeEnviromentName = (event) => {
        setEnviromentName(event.target.value);
        
    }

    const onChangePassword = (event) => {
        setPassword(event.target.value);
    }

    const onChangeIsActive = (event) => {
        setIsActive(event.target.checked);
    }

    useEffect(() => {
        getProjects(0, 0, null, null, JSON.stringify({}));
        getEnviromentList(0, 0, null, null, JSON.stringify({}));
    },[])

    

    const onChangeBaseUrl = (event) => {
        setBaseUrl(event.target.value);
        if (!isValidURL(event.target.value)) {
            setUrlError('Invalid URL format');
        } else {
            setUrlError('');
        }
    };

    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const isValidURL = (url) => {
        const regex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/i;
        return regex.test(url);
    };

    const Cancel=()=>{
        setSelectedProject('');
        setEnviromentName('');
        setUserName('');
        setPassword('');
        setBaseUrl('');
        setIsActive(false);
        setShowEditButton(false);
    }

    const UpdateProjEnvironment = (row) => {
        console.log(row)
        setShowEditButton(true)
        setId(row.env_id)
        setSelectedProject(row.proj_id)
        setEnviromentName(row.name)
        setUserName(row.username)
        setPassword(row.password)
        setBaseUrl(row.url) 
        if(row.is_active === "False"){
            setIsActive(false);
        }else{
            setIsActive(true);
        }
    }


    const Update = () => {
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        
        // Define the object with the necessary parameters
        const obj = {
            env_id:Id,
            proj_id:selectedProject,
                name:EnviromentName,
                username: UserName,
                is_active: IsActive,
                password: Password,
                url: Base_url
            
        };
    
        // Call the update function and then refresh the list on success
        updateEnviroment(obj)
            .then(() => {
                getEnviromentList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters))
                setSelectedProject('');
                setEnviromentName('');
                setUserName('');
                setPassword('');
                setBaseUrl('');
                setIsActive(false);
                setId('');
                setShowEditButton(false); 
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const Delete = (row) => {
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        deleteEnviroment(row.env_id)
          .then(() => {
            getEnviromentList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters))
        })
          .catch((error) => {
            console.error('Error:', error);
          });
      }

    const checkConnection=(row)=>{
        // showConfirmationAlert('Please Wait While We Check Your Credentials', 'This might take a few moments.',false)
        // .then((result) => {
        // if (result.isConfirmed) {
        // console.log("User Confirmed")
        // } 
        // });
        toast.warning('Please Wait While We Check Your Credentials This might take a few moments')
        const obj={
            Base_url:row.url,
            Username:row.username,
            Password:row.password
        }
        EnviromentService.CheckEnviroment(obj).then((res)=>{
            console.log("Verify Credentials ", res)
            if(res.data.message === "True"){
                toast.success("Credentials Verify Sucessfully ")
            }else{
                toast.error("Credentials Failed ")
            }
            
        }).catch((err)=>{
            console.log("error",err)
        })

        
    }

    useEffect(() => {
        const dataWithIdsAndProjectName = listOfEnviroment?.map((row, index) => {
            const matchedProject = listOfProject.find(project => project.proj_id === row.proj_id);
            return {
                ...row,
                id: row.env_id,
                proj_name: matchedProject ? matchedProject.proj_name : 'Unknown Project',
                is_active:row.is_active?"True":"False"
            };
        });
        setEnviromentList(dataWithIdsAndProjectName);
        setTotalCount(listOfEnviroment?.length);
    }, [listOfEnviroment, listOfProject]);
    
      const onSubmit = (event) => {
        event.preventDefault();
        if (UserName === '') {
            toast.error('User Name can not be empty');
            return;
        }
        else if (Base_url === '') {
            toast.error('URL can not be empty');
            return;
        }
        else if (Password === '') {
            toast.error('Password can not be empty');
            return;
        } else if (error || urlerror) {
            if (error) {
                toast.error(error);
            }
            if (urlerror) {
                toast.error(urlerror);
            }

        }
        else {

            const obj = {
                proj_id:selectedProject,
                name:EnviromentName,
                username: UserName,
                is_active: IsActive,
                password: Password,
                url: Base_url
            }
         

            createEnviroment(obj)
                .then((res) => {
                    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
                    getEnviromentList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters))
                    setSelectedProject('');
                    setEnviromentName('');
                    setUserName('');
                    setPassword('');
                    setBaseUrl('');
                    setIsActive(false);
                    setShowEditButton(false); 
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }
    
    return (
        <>
            <div>
                <Card
                    raised
                    sx={{
                        minWidth: "100%",
                        overflowX: "auto"
                    }}>
                    <Typography variant="h5" sx={{
                        p: 2
                    }}>
                        Add Project Environment
                    </Typography>
                    <Divider></Divider>
                    <CardContent>
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    Project:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Select  className="form-control" onChange={handleSelectChange} value={selectedProject}  >
                                <MenuItem value="select" key="" disabled="true">Select Project</MenuItem>
                                    {listOfProject?.map((option,index) => (
                                        <MenuItem value={option?.proj_id} key={index}>{option?.proj_name}</MenuItem>
                                    ))}
                                </Select>
                                {globalError && <ErrorBox message={globalError} onClose={() => setGlobalError('')} />}

                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                Environment Name:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    <TextField placeholder="Environment Name" onChange={onChangeEnviromentName} value={EnviromentName} />
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    User Name:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    <TextField placeholder="Enter UserName" onChange={onChangeUserName} value={UserName} />
                                </Typography>
                                
                            </Grid>

                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>Password:</Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField
                                    placeholder="Enter Password"
                                    required
                                    onChange={onChangePassword}
                                    value={Password}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: Password && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    Url:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField placeholder="Enter Url" required onChange={onChangeBaseUrl} value={Base_url} />
                                {urlerror && <span style={{ color: 'red' }}>{urlerror}</span>}

                            </Grid>
                            
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    Is Active:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Checkbox className="form-check-input" checked={IsActive} value={IsActive}
                                    onChange={onChangeIsActive} />

                            </Grid>

                        </Grid>

                        <div style={{ marginBottom: '30px' }}>
                            {showEditButton ? (
                                <>
                                    <Button sx={{ float: 'right' }} colorStyle={'cancel'} onClick={Cancel}>
                                        Back
                                    </Button>
                                    <Button sx={{ float: 'right' }} onClick={Update}>
                                        Update Project Environment
                                    </Button>

                                </>
                            ) : (
                                <Button sx={{ float: 'right' }} onClick={onSubmit}>
                                    + Add Project Environment
                                </Button>
                            )}
                            
                        </div>
                    </CardContent>

                </Card>
                <br></br>
                <Card
                    raised
                    sx={{
                        minWidth: "100%",
                        overflowX: "auto"
                    }}>
                    <Typography variant="h5" sx={{
                        p: 2
                    }}>
                        Project Environment List
                    </Typography>
                    <Divider></Divider>

                    <CardContent>
                { EnviromentList &&
                    <ServerPaginationGridNoRowCount
                        setPaginationModals={setPaginationModals}
                        paginationModals={paginationModals}
                        rowsData={EnviromentList.length > 0 ? EnviromentList : []}  
                        total={totalCount}
                        columns={columns}
                        columnWidth={width}
                        setSortModels={setSortModels}
                        sortModels={sortModels}
                        setFilterModels={setFilterModels}
                        filterModels={filterModels}
                    />}
                </CardContent>

                </Card>

                <Modal
                open={isOpen}
                onClose={handleAddPersonaClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description">
                <Box component="form"
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                        <Persona EnvList={EnviromentData}></Persona>
                        
                    <div className="modal-footer">
                        <Button onClick={handleAddPersonaClose} className="modalButton modalCloseButton" colorStyle='cancel'>Close</Button>
                    </div>
                </Box>
            </Modal>

            </div>
            <div>


            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    listOfProject: state?.auth?.listProject?.rows,
    listOfEnviroment:state?.Enviroment?.EnviromentList?.rows,

});

const mapDispatchToProps = {
    getProjects,getEnviromentList,createEnviroment,updateEnviroment,deleteEnviroment,resetEnv_Persona
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);


