import {GET_PERSONA_LIST,CREATE_PERSONA,
    DELETE_PERSONA,
    UPDATE_PERSONA,ENV_PERSONA,RESET_ENV_PERSONA  } from "../actions/types";
    
      const initialState = {
        PersonaList: null,
        Env_personaList:null
      };
      
      const Enviroment_Persona = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          case GET_PERSONA_LIST:
            return {
              ...state,
              PersonaList: payload,
              
          };          
            case CREATE_PERSONA:
              return {
                ...state,
                isPersonaCreated: true,
            };
            case DELETE_PERSONA:
              return {
                ...state,
                isPersonaDeleted: true,
            };
            case UPDATE_PERSONA:
              return {
                ...state,
                isPersonaUpdated: true,
            };
            case ENV_PERSONA:
              return {
                ...state,
                Env_personaList: payload,
            };
            case RESET_ENV_PERSONA:
              return {
                ...state,
                Env_personaList: null,
            };
            

          default:
            return state;
        }
      };
      export default Enviroment_Persona;
      