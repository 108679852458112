import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Tooltip, Typography, Grid, TextField } from '@mui/material';
import { getTestJobs, getSuiteTestJobs } from "../redux/actions/testCaseReport";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useState, useEffect, } from "react";
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Divider from "@mui/material/Divider";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { modalPopUpStyleLarge } from '../styles/getLPTheme';
import TestJobService from '../services/testJob.service'
import Box from '@mui/material/Box';
import StopIcon from '@mui/icons-material/Stop';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import DateFormatter from '../components/DateFormatter';
import ENV_VAR from "../common";
import '../styles/custom.css';
import '../styles/common.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import showConfirmationAlert from "../components/Confirm";
import EditIcon from "@mui/icons-material/Edit";


export const TestCaseJobs = ({ getTestJobs, getSuiteTestJobs, listOfJob, suiteJobList, deleteTestJobList }) => {

  const [openRows, setOpenRows] = React.useState({});
  const params = useParams();
  const { state } = useLocation();
  const [suiteId, setSuiteId] = useState('');
  const [suiteName, setSuiteName] = useState('');
  const [video, setVideo] = useState('')
  const [testCase, setTestCase] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [open_video, setOpenVideo] = React.useState(false);
  const [image, setImage] = useState('')
  const [StepData, SetStepData] = useState([])
  const [TestData, SetTestData] = useState([])
  const [remarks, setRemarks] = useState('')
  const [open_remarks, setOpenRemarks] = React.useState(false);
  const navigate = useNavigate();
  const Auth = JSON.parse(localStorage.getItem("authentication"));
  const navigateToJira = (row, row2) => {
     
    const obj = {
      message: row.message,
      stepName: row.step_name,
      TestcaseName: row2.test_case_name,
      video: row2.video_url,
      image: row.screenshot,

    }

    navigate('/Jira', { state: obj });
  }

  const handleClose = () => {
    setOpen(false);

  };
  const updatePopUp = (image) => {
    setOpen(true);
    setImage(image)
  }

  const updatePopUpVideo = (video) => {
    setOpenVideo(true);
    setVideo(video)
  }
  const handleVideoClose = () => {
    setOpenVideo(false);
  };

  useEffect(() => {

    if (state) {

      setSuiteId(state?.suite_id)
      setSuiteName(state?.suite_name)
      getSuiteTestJobs(state.id);
    } else {
      getTestJobs(Auth["proj_id"]);
    }

  }, [Auth["proj_id"]]);

  useEffect(() => {
    if (params.sys_name === 'TestCaseReport') {
      setSuiteId(null);
      setSuiteName(null);
      setTestCase(listOfJob);
    } else {

      setTestCase(suiteJobList);
    }
  }, [params.sys_name, listOfJob, suiteJobList, testCase])

  const onEdit = (record, parentRecord) => {
    SetStepData(record)
    SetTestData(parentRecord)
    setOpenRemarks(true)
    setRemarks(record.remarks)
  }
  const onRemarksClose = () => {
    setOpenRemarks(false)
    setRemarks('')
  }
  const onChangeRemarks = (event) => {
    setRemarks(event.target.value)
  }

  const Submit = () => {
    let obj = {
      id: StepData?.id,
      remarks: remarks
    }
    console.log("Object", obj)
    TestJobService.updateRemarks(obj)
      .then((res) => {
        getTestJobs();
        setOpenRemarks(false)
        console.log("response", res)
        toast.success("Remarks Added Sucessfully")
      })
      .catch((error) => {
        console.error('Error:', error);
        setOpenRemarks(false)
        toast.error(error)
      });
  }


  const toggleRow = (id) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const renderChildTable = (parentRowId) => {
    var parentRecord;
    if (suiteId) {
      parentRecord = suiteJobList.find((row) => row.id === parentRowId);
    } else {
      parentRecord = listOfJob.find((row) => row.id === parentRowId);
    }
    if (openRows[parentRowId] && parentRecord) {
      const childRecords = parentRecord.sellogs;
      return (
        <Table>
          <TableHead>
            <TableRow className='th' >
              <TableCell >Screenshot</TableCell>
              <TableCell >Step Name</TableCell>
              <TableCell >Log Type</TableCell>
              <TableCell >Status</TableCell>
              <TableCell >Message</TableCell>
              <TableCell >Remarks</TableCell>
              <TableCell >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {childRecords.map((record) => (
              <TableRow key={record.id}>
                <TableCell>{record.screenshot && <Link onClick={() => updatePopUp(record)} ><img src={`${process.env.REACT_APP_API_URL}/api/v1/getScreenshot/${record.id}?token=${token}`} alt="Screenshot" style={{ maxWidth: '100px' }} /></Link>}</TableCell>
                <Tooltip title={record.step_name}><TableCell>{truncateText(record.step_name, 20)}</TableCell></Tooltip>
                <TableCell>{record.log_type}</TableCell>
                <TableCell>
                  {record.status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{record.status}</div>) :
                    record.status === 'stop' ? (<div className="badge badge-opacity-warning text-capitalize">{record.status}<StopIcon style={{ color: '#FF0000' }} /></div>) :
                      record.status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{record.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                        record.status === 'failure' ? (<div className="badge badge-danger text-capitalize">{record.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                          (<div className="badge badge-opacity-success text-capitalize">{record.status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)}
                </TableCell>

                <TableCell className="text-break" style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>{record.message}</TableCell>

                <TableCell
                  className="text-break"
                  style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}
                >
                  {/* <Tooltip title={record.remarks}>{truncateText(record.remarks, 20)}</Tooltip> */}
                  {record.remarks && (
                    <Tooltip title={record.remarks}>
                      {truncateText(record.remarks, 20)}
                    </Tooltip>
                  )}
                  <Tooltip title="Edit"><EditIcon className="action-icon" onClick={() => onEdit(record, parentRecord)} titleAccess='Edit' /></Tooltip>
                </TableCell>

                <TableCell >
                  {record.status === 'error' || record.status === 'failure' ?
                    <Button onClick={() => (navigateToJira(record, parentRecord))} colorStyle="cancel"> Create Ticket</Button> : ''}
                </TableCell>

              </TableRow>

            ))}
          </TableBody>
        </Table>
      );
    }
    return null;
  };

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text?.substring(0, maxLength) + '...';
  };

  var tableRows;
  const navigateToSuiteExecutionReport = (id) => {
    navigate('/SuiteExecutionReport', { state: id });
  }

  const navigateToDetailcaseReport = () => {
    navigate('/detail-report');
  }

  const deleteJob = (row) => {
    console.log(row);
    showConfirmationAlert('Are you sure you want to Delete ?')
      .then((result) => {
        if (result.isConfirmed) {
          TestJobService.deleteTestJobList(row.id).then((res) => {
            toast.success(res.data.message)
            getTestJobs();
          }).catch((error) => {
            console.error('Error:', error);
          });
        }
      });
  }



  tableRows = testCase?.map((row, index) => (
    <React.Fragment key={row.id}>
      <TableRow>
        <Tooltip title={row.test_case_name}>
          <TableCell>{truncateText(row.test_case_name, 30)}</TableCell>
        </Tooltip>
        <TableCell>{row.total_steps}</TableCell>
        <TableCell><DateFormatter date={row.execution_time} /></TableCell>
        <TableCell><DateFormatter date={row.completion_time} /></TableCell>
        <TableCell>
          {row.status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{row.status}</div>) :
            row.status === 'stopped' ? (<div className="badge badge-opacity-warning text-capitalize">{row.status}<StopIcon style={{ color: '#FF0000' }} /></div>) :
              row.status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{row.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                row.status === 'failure' ? (<div className="badge badge-danger text-capitalize">{row.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                  (<div className="badge badge-opacity-success text-capitalize">{row.status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => toggleRow(row.id)}>
            {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {!suiteId && row.status !== 'running' && (
          <TableCell>
            {row.video_url && (
              <Link onClick={() => updatePopUpVideo(row)} title="Play">
                <SmartDisplayIcon style={{ color: 'red', cursor: 'pointer' }}></SmartDisplayIcon>
              </Link>
            )}
          </TableCell>


        )}
        {!suiteId && row.status !== 'running' && (
          <TableCell>
            <Tooltip title="Delete">
              <DeleteIcon
                style={{ color: '#d71b1b' }}
                onClick={() => deleteJob(row)}
                className="action-icon clickIconClassDelete"
              />
            </Tooltip>
          </TableCell>
        )}
        {/* <TableCell>
        <Tooltip title="Delete">
            <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteJob(row)} className="action-icon clickIconClassDelete" />
        </Tooltip>
      </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell colSpan={9}>
          {renderChildTable(row.id)}
        </TableCell>
      </TableRow>

    </React.Fragment>
  ));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          sx={{
            ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off">

          <div className="modal-header">
            <img src={`${process.env.REACT_APP_API_URL}/api/v1/getScreenshot/${image.id}?token=${token}`} alt="Screenshot" style={{ maxWidth: '90%', height: 'auto' }} />
          </div>

          <div className="modal-footer">
            <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open_video}
        onClose={handleVideoClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          sx={{
            ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off">

          <div className="modal-header">
            <video controls style={{ maxWidth: '100%' }}>
              <source src={`${process.env.REACT_APP_API_URL}/api/v1/getVideo/${video.id}?token=${token}`} type="video/mp4" />
            </video>
          </div>

          <div className="modal-footer">
            <Button onClick={handleVideoClose} className="modalButton modalCloseButton">Close</Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open_remarks}
        onClose={onRemarksClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          component="div"
          className="modalContent"
          noValidate
          autoComplete="off"
        >
          {/* Header */}
          <div className="modal-header">
            <h4 >
              <Tooltip title={TestData?.test_case_name}>
                <b>{truncateText(TestData?.test_case_name, 20)}</b>
              </Tooltip> :- <Tooltip title={StepData?.step_name}>
                {truncateText(StepData?.step_name, 20)}
              </Tooltip>
            </h4>
          </div>
          {/* Content */}
          <div className="modal-body">
            <Grid item xs={8} sm={4}>
              <Typography variant="subtitle2" fontSize={14}>
                <TextField placeholder="Enter Remarks" onChange={onChangeRemarks} multiline value={remarks} />
              </Typography>
            </Grid>
          </div>
          <div className="modal-footer">
            <Button onClick={onRemarksClose} className="modalButton modalCloseButton" colorStyle='cancel'>Close</Button>
            <Button onClick={() => Submit()} className="modalButton modalCloseButton">Submit</Button>
          </div>
        </Box>
      </Modal>

      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"
        }}>
        <Typography variant="h5" sx={{
          p: 2
        }}>
          {suiteId ? `${suiteName} - Test Cases Report` : `Test Case Report`}
          {suiteId ? <Button sx={'float: right ; marginBottom: 2px; font-weight: bold'} size="small" onClick={() => (navigateToSuiteExecutionReport(suiteId))}>Suite Report</Button> : null}
          {!suiteId ? <Button sx={'float: right ; marginBottom: 2px; font-weight: bold'} size="small" onClick={() => (navigateToDetailcaseReport())}>Detail Reports</Button> : null}
        </Typography>
        <Divider></Divider>
        <CardContent>
          <TableContainer component={Card}>
            <Table  >
              <TableHead >
                <TableRow className='th'>
                  <TableCell >Test Case Name</TableCell>
                  <TableCell >Total Steps</TableCell>
                  <TableCell >Start Time</TableCell>
                  <TableCell >Completion Time</TableCell>
                  <TableCell >Status</TableCell>
                  <TableCell >Details</TableCell>
                  {!suiteId && <TableCell>Video</TableCell>}
                  {!suiteId && <TableCell>Action</TableCell>}

                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows}
              </TableBody>
            </Table>

          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  suiteJobList: state.testCaseReport?.suiteTestCaseList,
  listOfJob: state.testCaseReport?.testJobList,
}
);

const mapDispatchToProps = {
  getTestJobs,
  getSuiteTestJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCaseJobs);