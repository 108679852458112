import { GET_PERSONA_LIST,CREATE_PERSONA,
    DELETE_PERSONA,
    UPDATE_PERSONA,ENV_PERSONA,RESET_ENV_PERSONA } from "./types";
import { toast } from 'react-toastify';
import EnviromentPersonaService from "../../services/environmentPersona.service";

export const getPersonaList = (EnvId) => async (dispatch) => {
    
    try {
        let res = await EnviromentPersonaService.getPersonaList(EnvId);
        if (res.status === 200) {
            dispatch({
                type: GET_PERSONA_LIST,
                payload: res.data
            });
        } else {
            toast.error("Unable to fetch Project Environment : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


export const createPersona = (object) => async (dispatch) => {
    try {
        let res = await EnviromentPersonaService.createPersona(object);
        if (res.status === 200) {
            // dispatch({
            //     type: CREATE_PERSONA,
            //     payload: res.data
            // });
            return res
        } else {
            toast.error("Unable to create Project Environment " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const deletePersona = (id) => async (dispatch) => {
    try {
        console.log("id",id)
        let res = await EnviromentPersonaService.deletePersona(id);
        if (res.status === 200) {
            dispatch({
                type: DELETE_PERSONA,
                payload: res.data
            });
            toast.success("Persona deleted successfully");
        } else {
            toast.error("Unable to delete Persona: " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const Env_Persona = (id) => async (dispatch) => {
    try {
        let res = await EnviromentPersonaService.env_Persona(id);
        if (res.status === 200) {
            dispatch({
                type: ENV_PERSONA,
                payload: res.data
            });
            // toast.success("Persona deleted successfully");
        } else {
            toast.error("Unable to delete Persona: " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const resetEnv_Persona = () => async (dispatch) => {
  dispatch({
    type: RESET_ENV_PERSONA,
    payload: null,
  });
};


export const updatePersona = (object) => async (dispatch) => {
    try {
        let res = await EnviromentPersonaService.updatePersona(object);
        if (res.status === 200) {
            dispatch({
                type:UPDATE_PERSONA ,
                payload: res.data
            });
            
        } else {
            toast.error("Unable to update Persona : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


