import * as React from 'react';
import { createuser, updateuser, deleteuser, getuserList, getuserbyid } from "../redux/actions/manageuser";
import { connect } from "react-redux";
import { Card, CardContent, Button, Typography, Tooltip } from '@mui/material';
import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import '../styles/project.css';
import Checkbox from '@mui/material/Checkbox';
import Divider from "@mui/material/Divider";
import '../styles/common.css';
import '../styles/project.css';
import '../styles/custom.css';
import validationRules from '../components/ValidationRules';
import validateForm from '../components/Validate';
import ErrorBox from '../components/ErrorBox';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid"
import EditIcon from "@mui/icons-material/Edit";
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import Search from '../components/Search'
export const Users = ({ createuser, updateuser, getuserList, Listofuser, totalCount }) => {
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModels, setSortModels] = useState([]);
  const [filterModels, setFilterModels] = useState({ items: [] });
  const columns = [
    {
      field: "u_name",
      headerName: "Name",
    },
    {
      field: "u_email",
      headerName: "Email",
    },
    {
      field: "u_mobile",
      headerName: "Mobile",
    },
    {
      field: "u_is_admin",
      headerName: "Admin",
    },
    {
      field: "u_status",
      headerName: "Status",
    },
    {
      field: "u_is_super_admin",
      headerName: "Super Admin",
    },
    {
      field: "aciton",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <EditIcon className="action-icon" sx={'color: #34B1AA'} onClick={() => updatePopUp(params.row)} />
      ),
    },
  ];
  const initialValues = {
    name: '',
    email: '',
    password: '',
    mobile: '',
    isAdmin: false,
    status: false
  };
  const fields = [
    { name: 'name', label: 'Name', type: 'text', validations: [validationRules.required] },
    { name: 'email', label: 'Email', type: 'email', validations: [validationRules.required, validationRules.email] },
    { name: 'password', label: 'Password', type: 'password', validations: [validationRules.required] },
    { name: 'mobile', label: 'Mobile', type: 'text', validations: [] },
    { name: 'isAdmin', label: 'IsAdmin', type: 'checkbox', validations: [] },
    { name: 'status', label: 'Status', type: 'checkbox', validations: [] },
  ];
  const [isAdmin, setisAdmin] = useState(false);
  const [status, setstatus] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [globalError, setGlobalError] = useState('');
  const [userDetails, setuserDetails] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [width, setWidth] = useState(100 / columns.length + '% !important');

  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };
  const handleSearchChange = (event) => {

    setSearchQuery(event.target.value);
  };

  useEffect(() => {

    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    getuserList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(searchQuery));
  }, [paginationModals, sortModels, filterModels, searchQuery])

  const validate = () => {
    const { newErrors, globalError } = validateForm(values, fields);
    setGlobalError(globalError);
    return Object.keys(newErrors).length === 0;
  };

  const handleOpen = () => {
    setShowEditButton(false);
    setValues(initialValues);
    setisAdmin('');
    setuserDetails('');
    setstatus('');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onChangeName = (event) => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value });
  }

  const handleCheckboxChange = (event) => {
    setisAdmin(event.target.checked);
    setValues({ ...values, isAdmin: event.target.checked });
  }
  const handleCheckboxChangestatus = (event) => {
    setstatus(event.target.checked);
    setValues({ ...values, status: event.target.checked });
  }

  const create = () => {
    if (validate()) {
      setShowEditButton(false);
      try {
        createuser(values)
          .then(response => {
            const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
            getuserList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(searchQuery));
            handleClose();
          })
      } catch (error) {
        console.error('Error:', error);
      };
      setOpen(false);
    }
  }

  const updateData = () => {
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    if (validate()) {
      setuserDetails(values);
      updateuser(values);
      getuserList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(searchQuery));
      setOpen(false);
    }
  }
  const updatePopUp = (userDetails) => {
    setOpen(true);
    setShowEditButton(true);
    setisAdmin(userDetails.u_is_admin);
    setstatus(userDetails.u_status);
    setValues({
      id: userDetails.u_id,
      name: userDetails.u_name,
      email: userDetails.u_email,
      mobile: userDetails.u_mobile,
      password: userDetails.u_password ? userDetails.u_password : '123456',
      isAdmin: userDetails.u_is_admin,
      status: userDetails.u_status
    });
    setuserDetails(userDetails);
    console.log(userDetails);
  }
  const ListofuserRows = Listofuser?.map((item) => {
    return { ...item, id: item.u_id }
  });

  useEffect(() => {
    updateTableHeight(ListofuserRows?.length);
  }, [paginationModals.pageSize, ListofuserRows]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: 700, // Adjust width as needed
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            borderRadius: 4,
          }}
          component="div"
          className="modalContent"
          noValidate
          autoComplete="off"
        >
          <div className="modal-header">
            <h3 className="label-bold">
              {!showEditButton ? 'Create User' : 'Update User'}
            </h3>
          </div>

          <div style={{ position: 'relative', flex: '1 1 auto', padding: '30px' }}>
            <div className="modalForm">
              {globalError && <ErrorBox message={globalError} onClose={() => setGlobalError('')} />}
              <div className="row">
                {fields.map((field) => {
                  if (field.type === "text") {
                    return (
                      <div className="col-md-6 mt-2">
                        <label><b>{field.label}:</b></label><br></br>
                        <TextField
                          type={field.type}
                          name={field.name}
                          value={values[field.name]}
                          onChange={onChangeName}
                        />

                      </div>

                    )
                  } else if (field.type === "email") {
                    return (

                      <div className="col-md-6 mt-2">
                        <label><b>{field.label}:</b></label><br></br>
                        <TextField
                          type={field.type}
                          name={field.name}
                          value={values[field.name]}
                          onChange={onChangeName}
                        />
                      </div>

                    )
                  } else if (field.type === "password") {
                    return (

                      <div className="col-md-6 mt-2">
                        <label><b>{field.label}:</b></label><br></br>
                        <TextField
                          type={field.type}
                          name={field.name}
                          value={!showEditButton ? values[field.name] : '123456'}
                          onChange={onChangeName}
                          disabled={showEditButton}
                        />
                      </div>

                    )
                  } else if (field.type === "checkbox" && field.name === "isAdmin") {
                    return (<div className="col-md-6 mt-2">
                      <label><b>Is Admin:</b></label>&nbsp;&nbsp;
                      <Checkbox
                        checked={isAdmin}
                        value={isAdmin}
                        onChange={handleCheckboxChange}
                      ></Checkbox>
                    </div>
                    )
                  } else {
                    return (<div className="col-md-6 mt-2">
                      <label><b>Status:</b></label>&nbsp;&nbsp;
                      <Checkbox
                        checked={status}
                        value={status}
                        onChange={handleCheckboxChangestatus}
                      ></Checkbox>Active
                    </div>)
                  }

                })}
              </div>

            </div>
          </div>

          <div className="modal-footer">
            {!showEditButton ? <Button onClick={create} className="modalButton">Create User</Button> : <Button onClick={updateData} className="modalButton">Update</Button>}
            <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
          </div>
        </Box>
      </Modal>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"  // Enable scrolling
        }}>
        {/* <Typography variant="h5" sx={{
          p: 2
        }}>
          Users List
          <Tooltip title="Add User">
            <Button sx={'float: right ; marginBottom: 2px; font-weight: bold'} size={"small"} onClick={handleOpen}> + Add User</Button>
          </Tooltip>

        </Typography> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
          <Typography variant="h5">
            <Box sx={{ display: 'flex', mb: 2, flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}>
              <label style={{ marginRight: '20px' }}> User List</label>
              <Box sx={{ flexGrow: 1 }} />
              <Search onChangeQuery={handleSearchChange} value={searchQuery} />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Tooltip title="Add User">
                  <Button sx={'float: right ; marginBottom: 2px; font-weight: bold'} size={"small"} onClick={handleOpen}> + Add User</Button>
                </Tooltip>
              </Box>
            </Box>
          </Typography>
        </Box>
        <Divider></Divider>
        <CardContent>
          <div style={{ height: tableHeight, width: '100%' }}>
            {/* <Search onChangeQuery={handleSearchChange} value={searchQuery} />  */}
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={ListofuserRows && ListofuserRows.length ? ListofuserRows : []}
              total={ListofuserRows && totalCount ? totalCount : 0}
              columns={columns}
              columnWidth={width}
              setSortModels={setSortModels}
              sortModels={sortModels}
              setFilterModels={setFilterModels}
              filterModels={filterModels}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );

}
const mapStateToProps = (state) => ({
  Listofuser: state.manageuser?.userList?.rows,
  totalCount: state.manageuser?.userList?.count
});

const mapDispatchToProps = {
  createuser, updateuser, deleteuser, getuserList, getuserbyid
};


export default connect(mapStateToProps, mapDispatchToProps)(Users);