import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Link,
  Box,
} from "@mui/material";
import DateFormatter from "../components/DateFormatter";
import {
  getDashboard,
  getsuitelist,
  getDashboardById,
} from "../redux/actions/dashboard";
import { testCaseList, getAllTestCases } from "../redux/actions/testcase";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import * as React from "react";
import DoughnutChart, { DataList } from "../components/DoughnutChart";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
import { Chart } from "chart.js";

import {
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  Title,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Filler,
} from "chart.js";
import "../styles/custom.css";
import "../styles/common.css";
import "../styles/style.css";
import { useNavigate } from "react-router-dom";
import Select, { SelectChanvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import StopIcon from '@mui/icons-material/Stop';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  Title,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Filler
);

export const Dashboard = ({
  getDashboard,
  getDashboardById,
  dashboardList,
  allTestCasesList,
  getAllTestCases,
  getsuitelist,
  suitelist,
}) => {
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState("");
  const [defaultsuite, setDefaultsuite] = useState("select");
  const [status, setStatus] = useState(false);
  const [DateFormat, setDateFormat] = useState(localStorage.getItem("date_time_format"));
  const authData = JSON.parse(localStorage.getItem('authentication')) || {};

  localStorage.setItem('authentication', JSON.stringify(authData));

  useEffect(() => {
    getAllTestCases(0, 5, null, null, JSON.stringify({}), 'All', authData["proj_id"]);
    getDashboard(authData["proj_id"]);
    getsuitelist(authData["proj_id"]);
  }, [authData["proj_id"]]);

  const handleSelectChange = (event) => {
    setDefaultsuite(event.target.value);
    getDashboardById(event.target.value);
  };

  useEffect(() => {
    if (dashboardList && dashboardList.length === 0) {
      setDashboard(null);
    } else {
      setDashboard(dashboardList);
      setDefaultsuite(dashboardList?.[0]?.suite_id);
    }
  }, [dashboardList]);


  const latestSuite = DataList(dashboardList)

  const tableRows = dashboardList?.map((row, index) => (
    <TableRow className="td" key={index}>
      <TableCell>{row.suite_name}</TableCell>
      <TableCell><DateFormatter date={row.execution_time} /></TableCell>
      <TableCell><DateFormatter date={row.completion_time} /></TableCell>
      <TableCell>
        <div>
          <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
            <p className="text-success">{row.pass_percent}%</p>
            <p>
              {row.passed}/{row.total_cases}
            </p>
          </div>

          <div
            className="progress"
            role="progressbar"
            aria-label="Success example"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              style={{
                width: `${row.pass_percent}%`,
                backgroundColor: "green",
              }}
            ></div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {row.suite_status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{row.suite_status}</div>) :
          row.suite_status === 'stopped' ? (<div className="badge badge-opacity-warning text-capitalize">{row.suite_status}<StopIcon style={{ color: '#FF0000' }} /></div>) :
            row.suite_status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{row.suite_status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
              row.suite_status === 'failure' ? (<div className="badge badge-danger text-capitalize">{row.suite_status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                (<div className="badge badge-opacity-success text-capitalize">{row.suite_status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)}
      </TableCell>
    </TableRow>
  ));

  const navigateToSuiteTestCase = (defaultsuite) => {
    navigate("/DashboardReport", { state: defaultsuite });
  };

  const navigateToAllTestCase = () => {
    navigate("/testcases/all");
  };

  const navigateToTestCase = (row) => {
    let obj = {
      case_id: row,
    };
    navigate(`/testcase`, { state: obj });
  };

  return (
    <div>
      {dashboard ? (
        <div className="content-wrapper">
          <Box
            style={{
              margin: "10px",
            }}
            sx={{
              margin: { xs: 0, sm: "10px" },
            }}
          >
            <Button onClick={() => navigateToSuiteTestCase(defaultsuite)}>
              Report
            </Button>
          </Box>
          <div className="row ">
            <div className="row">
              <div
                className="col-lg-12"
                id="report"
                style={{ width: "100%", margin: "10px", height: "100%" }}
              >
                <div className="row">
                  {/*
                <!--Report Section--> */}
                  <Box sx={{ marginBottom: "10px" }}>
                    <div className=" tab-content-basic ">
                      <div className="tab-pane fade show active">
                        <div className="row">
                          <div className="col-sm-12 ">
                            <Box className="statistics-details d-flex flex-wrap gap-2" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                              <div className="card p-2 rounded" style={{ flex: 1 }}>
                                <p className="statistics-title">
                                  <strong>Suite Name</strong>
                                </p>
                                <h3 className="rate-percentage">
                                  {latestSuite?.name}
                                </h3>
                              </div>
                              <div className="card p-2 rounded" style={{ flex: 1 }}>
                                <p className="statistics-title">
                                  <strong>Total Cases</strong>
                                </p>
                                <h3 className="rate-percentage">
                                  {latestSuite?.total_cases}
                                </h3>
                              </div>
                              <div className="card p-2 rounded" style={{ flex: 1 }}>
                                <p className="statistics-title">
                                  <strong>Start Time</strong>
                                </p>
                                <h3 className="rate-percentage">
                                  <DateFormatter date={latestSuite?.execution_time} />
                                </h3>
                              </div>
                              <div className="d-none d-md-block card p-2 rounded" style={{ flex: 1 }}>
                                <p className="statistics-title">
                                  <strong>Completion Time</strong>
                                </p>
                                <h3 className="rate-percentage">
                                  <DateFormatter date={latestSuite?.completion_time} />
                                </h3>
                              </div>
                              <div className="d-none d-md-block card p-2 rounded" style={{ flex: 1 }}>
                                <p className="statistics-title">
                                  <strong>Execution Time</strong>
                                </p>
                                <h3 className="rate-percentage">
                                  {latestSuite?.avg_time}
                                </h3>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>

                  <div className="col-lg-8 d-flex flex-column">
                    <div className="card grid-margin card-rounded">
                      <div className="card-body">
                        <div className="d-sm-flex justify-content-between align-items-start">
                          <h4 className="card-title card-title-dash">
                            {latestSuite?.name} Last 5 Trends
                          </h4>

                          <Select
                            onChange={handleSelectChange}
                            value={defaultsuite}
                          // style={{ width: "280px" }}
                          >
                            {suitelist?.map((option, index) => (
                              <MenuItem value={option?.suite_id} key={index}>
                                {option?.suite_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <LineChart dashboardList={dashboardList} />
                      </div>
                    </div>

                    <div className="col-lg-12 d-flex flex-column">
                      <div className="col-12 grid-margin card">
                        <div className="card card-rounded">
                          <div className="card-body">
                            <div className="d-sm-flex justify-content-between align-items-start">
                              <div>
                                <h4 className="card-title card-title-dash">
                                  {latestSuite?.name}: Test Cases
                                </h4>
                              </div>
                            </div>
                            <BarChart Data={dashboardList} style={{ height: "90%" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*
                  <!--Bar Graph-->
                  */}
                  </div>

                  <div className="col-lg-4 d-flex flex-column">
                    <div className="col-12 grid-margin stretch-card">
                      <div className="card card-rounded ">
                        <div className="card-body">
                          <div className="row">
                            <div style={{ width: "100%", height: "20%" }}>
                              <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="card-title card-title-dash">Latest Execution</h4>
                                <h4 className="card-title card-title-dash">{latestSuite?.avg_time}</h4>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "10%",
                                  margin: "10%",
                                }}
                              >
                                <DoughnutChart dashboardList={dashboardList} />
                              </div>


                              <h5>Suite Name: {latestSuite?.name}</h5>
                              <h5>Total Cases: {latestSuite?.total_cases}</h5>
                              <h5>Passed: {latestSuite?.doughnut_pass}</h5>
                              <h5>Fail: {latestSuite?.doughnut_failed}</h5>
                              <h5>Stop: {latestSuite?.doughnut_stop}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row flex-grow">
                      <div className="col-12 grid-margin stretch-card">
                        <div className="card card-rounded">
                          <div className="card-body">
                            <div className="row">
                              <h3>User test Cases</h3>
                              <div className="scroll">
                                <ul>
                                  {allTestCasesList &&
                                    allTestCasesList.map((testCase, index) => (
                                      <li key={index}>
                                        <Link onClick={() => navigateToTestCase(testCase.case_id)} title={testCase.case_name}>
                                          {testCase.case_name.length > 20 ? testCase.case_name.substring(0, 20) + '...' : testCase.case_name}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <Link onClick={() => navigateToAllTestCase()}>
                                All Test Cases
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*
                  <!--Todo List End--> */}
                  </div>
                  {/* </div>
                <div className="row"> */}
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <TableContainer component={Card} className="marginTop">
                        <Box style={{ overflowX: 'auto' }}>
                          <Table className="dashboard-table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Suite Name</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>Completion Time</TableCell>
                                <TableCell>Progress</TableCell>
                                <TableCell>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tableRows}
                            </TableBody>
                          </Table>
                        </Box>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Card style={{ width: "100%" }} className="marginTop">
            <div className="col-md-12 text-center">
              <h5 className="p-4">
                No Suite Executed Yet Please Execute one to View Dashboard
              </h5>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  //testCaseLists: state.auth?.allTestCasesList?.rows,
  allTestCasesList: state.auth?.allTestCasesList?.rows,
  dashboardList: state.dashboard?.dashboard,
  suitelist: state?.dashboard?.suitelist,
  projectCreated: state.auth.listProject
});

const mapDispatchToProps = {
  getDashboard,
  testCaseList,
  getAllTestCases,
  getsuitelist,
  getDashboardById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
